<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Subject *"
              invalid-feedback="Subject is required."
              ref="subject"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter subject name"
                v-validate="'required'"
                name="classField"
                ref="name"
                v-model="myObj.subject"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-row align-h="center" v-if="dataLoading" class="mt-2">
      <b-spinner
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-row align-h="center" v-else>
      <b-col xl="6" lg="8" md="12">
        <b-card>
          <h3 class="mt-1">Grading Policy</h3>
          <hr />
          <div
            v-for="(item, ind) in myObj"
            :key="ind"
            style="display: flex; align-items: center"
          >
            <b-col md="4" class="p-0">
              <b-form-group
                label="Percentage From"
                invalid-feedback="Perc from is required."
                ref="from"
              >
                <b-form-input
                  :id="'from' + ind"
                  type="number"
                  ref="from"
                  placeholder=""
                  v-model="item.percFrom"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" class="pr-0">
              <b-form-group
                label="Percentage To"
                invalid-feedback="Perc to is required."
                ref="to"
              >
                <b-form-input
                  type="number"
                  placeholder=""
                  ref="to"
                  v-model="item.percTo"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" class="pr-0">
              <b-form-group
                label="Grade"
                invalid-feedback="Grade is required."
                ref="grade"
              >
                <b-form-input placeholder="" ref="grade" v-model="item.grade" />
              </b-form-group>
            </b-col>
            <b-col md="2" class="mt-1 pr-0">
              <b-button
                v-if="ind == myObj.length - 1"
                @click="AddValue(ind + 1)"
                variant="primary"
                class="btn-icon rounded-circle mr-50"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
              <b-button
                v-if="myObj.length > 1"
                @click="removeValue(item, ind)"
                variant="outline-danger"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </div>
          <b-button
            class="mt-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="Add()"
            :disabled="request"
            block
          >
            <b-spinner v-if="request" small type="grow" />
            <span v-if="request == false"> Save </span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  mounted() {
    // window.addEventListener("keydown", (event) => {
    //   // console.log(event);
    //   // if (event.ctrlKey && event.keyCode === 83) {
    //   //   event.preventDefault();
    //   //   if (this.check == 1) {
    //   //     this.check = 0;
    //   //     this.Add();
    //   //   }
    //   // }
    // });
    window.addEventListener("keydown", this.handleEvent);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent);
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return pro.subject.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      check: 1,
      request: false,
      visibility1: false,

      sidebarTitle: "Add Subject",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      fields: [
        { label: "Subject", key: "subject" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: [
        {
          id: 0,
          percFrom: 0,
          percTo: 0,
          grade: "",
          campusID: this.$store.state.userData.cId,
        },
      ],
      newValue: {
        id: 0,
        percFrom: 0,
        percTo: 0,
        grade: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    handleEvent(event) {
      // console.log(event);
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault();
        if (this.check == 1) {
          this.check = 0;
          this.Add();
        }
      }
    },
    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);
      this.visibility = true;
      this.sidebarTitle = "Edit Subject";
      this.sidebarButton = "Update";
      var elem = this.$refs["subject"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        subject: "",
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add Subject";
      this.sidebarButton = "Save";
      var elem = this.$refs["subject"];
      elem.state = undefined;
    },
    AddValue(ind) {
      this.myObj.push({
        id: 0,
        percFrom: 0,
        percTo: 0,
        grade: "",
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.myObj);
      setTimeout(() => {
        let elem = document.getElementById("from" + ind);
        elem.focus();
        elem.select();
      }, 100);
    },
    async removeValue(item, ind) {
      if (item.id == 0) {
        this.myObj.splice(ind, 1);
        // console.log(this.myObj);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "GradingPolicy/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.myObj.splice(ind, 1);
        // console.log(this.myObj);
      }
    },
    CheckName() {
      var elem = this.$refs["subject"];
      if (this.myObj.subject == "" || this.myObj.subject > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckValues() {
      let state = true;
      this.myObj.forEach((el) => {
        el.percFrom = parseInt(el.percFrom);
        el.percTo = parseInt(el.percTo);
        if (
          isNaN(el.percTo) ||
          isNaN(el.percFrom) ||
          el.percFrom >= 100 ||
          el.percFrom < 0 ||
          el.percTo <= 0 ||
          el.percTo > 100 ||
          el.grade == ""
        ) {
          state = false;
        }
      });
      return state;
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "GradingPolicy?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      if (result.length > 0) this.myObj = result;
      else {
        this.myObj = [
          {
            id: 0,
            percFrom: 0,
            percTo: 0,
            grade: "",
            campusID: this.$store.state.userData.cId,
          },
        ];
      }
      // console.log("grad", this.myObj);
      this.dataLoading = false;
      setTimeout(() => {
        let first = document.getElementById("from0");
        first.focus();
        first.select();
      }, 100);
    },

    async Add() {
      // this.CheckName();
      if (this.CheckValues() == false) {
        this.check = 1;
        return this.$bvToast.toast("Please enter the policy correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        //Add
        // console.log("obj:", this.myObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "GradingPolicy/Save?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Grading Policy added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
        this.request = false;
        this.check = 1;
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
